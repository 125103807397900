.gt-alertBanner {
  $blockName: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: map-get($fontSizes, 14);
  margin: -1 * map-get($sizes, 6) -1 * map-get($sizes, 6) map-get($sizes, 4) -1 * map-get($sizes, 6);
  padding: map-get($sizes, 2) map-get($sizes, 4);
  position: sticky;
  top: -24px;
  z-index: 2;
  background: $alertBannerBgColor;
  color: $alertBannerMainColor;

  &__left {
    display: flex;
    align-items: center;
    gap: map-get($sizes, 3);

    &-icon {
      color: $alertBannerMainColor;
      margin-right: map-get($sizes, 2);
      font-size: map-get($fontSizes, 20);
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &--generateDocumentsVariant {
    color: $darkPurple;
    margin: 0;
    border-radius: map-get($sizes, 1);

    #{$blockName} {
      &__left-icon {
        font-size: map-get($fontSizes, 24);
        fill: $darkPurple;
      }

      &__right {
        display: none;
      }
    }
  }

  &--questionPageVariant {
    margin: -1 * map-get($sizes, 6) -1 * map-get($sizes, 6) map-get($sizes, 7) -1 * map-get($sizes, 6);
    z-index: 4;
  }
}