.gt-questionRow {
  $blockName: &;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $questionRowBgColor;
  border: 1px solid $questionRowBorderColor;

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: map-get($fontSizes, 14);
    padding: map-get($sizes, 2) map-get($sizes, 4);

    &-text {
      white-space: pre-line;
    }

    &-tooltip {
      color: $greyColor;
      white-space: pre-line;

      svg {
        height: map-get($sizes, 5);
        width: map-get($sizes, 5);
      }
    }
  }

  &__control {
    width: 100%;
    flex: 1 1;
    max-width: calc(33% - 36px);
  }

  &__indicators {
    flex: 0 0 80px;
    padding: map-get($sizes, 3);
    display: flex;
    justify-content: flex-end;

    &-icon {
      display: none;
      flex-direction: row;
      align-items: center;
      margin-right: map-get($sizes, 3);
    }

    &-iconFill {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
  }

  &__comment {
    flex: 1;
    padding: map-get($sizes, 1) map-get($sizes, 4) map-get($sizes, 1) 0;

    &-input {
      width: 100%;

      &>.MuiInputBase-root {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: $textAreaHeight;
        padding: map-get($sizes, 2);
        font-size: map-get($fontSizes, 12);
        background-color: $inputBgColor;
      }
    }
  }

  &__datePicker {
    input {
      height: map-get($sizes, 2);
      background-color: $inputBgColor;
      width: map-get($sizes, 172)
    }
  }

  &__separator {
    margin: 0 map-get($sizes, 3);

  }

  &--defaultAnswerState {
    #{$blockName} {
      &__indicators {
        &-icon {
          display: flex;
          color: $blueColor;
        }
      }
    }
  }

  &--notStandardState {
    #{$blockName} {
      &__indicators {
        &-icon {
          display: flex;
          color: $yellowColor;
        }
      }
    }
  }

  &--filledAnswerState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $filledAnswerStateColor;
        }
      }
    }
  }

  &--unfilledAnswerState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $unfilledAnswerStateColor;
        }
      }
    }
  }

  &--isNotAnsweredState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $isNotAnsweredStateColor;
        }
      }
    }
  }

  &--isInfoTextVariat {
    background-color: $questionRowInfoTextBgColor;

    #{$blockName} {
      &__title {
        font-weight: 600;
      }
    }
  }

  &--isHeadline {
    background-color: $questionRowHeadlineColor;

    #{$blockName} {
      &__title {
        font-weight: 600;
      }
    }
  }

  &--isLicense {
    align-items: flex-start;
  }

  &--clientDetails {
    display: block;

    #{$blockName} {
      &__title {
        height: map-get($sizes, 10);
      }

      &__control {
        width: 1024px;
        max-width: calc(50% - map-get($sizes, 5));
      }

      &__row {
        display: flex;
        flex-direction: row;

        &-title {}
      }
    }
  }

}

.gt-tootipHint {
  a {
    color: $whiteColor;
    cursor: pointer;
  }
}



.gt-clientDetails {
  border: 1px solid #ccc;
  border-radius: map-get($sizes, 2);
  padding: map-get($sizes, 4);
  margin-bottom: map-get($sizes, 4);
  background-color: #f9f9f9;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: map-get($fontSizes, 16);
    font-weight: bold;
    margin-bottom: map-get($sizes, 3);
    color: #333;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, 2);
  }

  &__row {
    display: flex;
    align-items: center;
    gap: map-get($sizes, 2);
    padding: map-get($sizes, 2) map-get($sizes, 4);
    margin: auto;


    label {
      font-size: map-get($fontSizes, 14);
      font-weight: 600;
      flex: 1 1;
      padding: 0 map-get($sizes, 4) 0 0;
    }

    .gt-TextField {
      flex: 1 1;
    }

    .gt-Select {
      flex: 1 1;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: map-get($sizes, 1);

      flex: 1 1;
      padding: 0 map-get($sizes, 4) 0 0;

      label {
        font-size: map-get($fontSizes, 14);
        font-weight: 600;
      }
    }

    &-buttons {
      display: flex;
      margin: auto;


      &-delete {
        color: $darkPurple80;
        cursor: pointer;
        border-radius: 6px;
        padding: 0 16px 0 0;
      }

      &-addClient {
        flex: 1 1;
        margin-top: map-get($sizes, 4);
        padding: map-get($sizes, 2);
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        height: map-get($sizes, 9);

        &.enabled {
          background-color: $darkPurple80;
          color: white;

          &:hover {
            background-color: $darkPurple80;
          }
        }

        &.disabled {
          background-color: #ddd;
          color: #888;
          cursor: not-allowed;
        }
      }
    }
  }

  &__signees {
    margin-top: map-get($sizes, 6);
    border-top: 1px solid #ddd;
    padding-top: map-get($sizes, 3);

    .gt-TextField {
      flex: 1 1;
      padding: 0 map-get($sizes, 7);
    }

    label {
      font-weight: 600;
      display: block;
      margin-bottom: map-get($sizes, 1);
    }

    .gt-clientDetails__signee {
      display: flex;
      gap: map-get($sizes, 4);
      margin-bottom: map-get($sizes, 1);
      margin-top: map-get($sizes, 1);

      span {

        flex: 1 1;
      }
    }

    .gt-clientDetails__add-signee {
      margin-top: map-get($sizes, 1);
      background-color: $darkPurple80;
      color: white;

      margin-left: map-get($sizes, 7);
      border-radius: 6px;
      cursor: pointer;

      &--disabled {
        background-color: #ddd;
        color: #888;
        cursor: not-allowed;

        &:hover {
          filter: grayscale(0%);
        }
      }

      &:hover {
        background-color: $darkPurple80;
      }
    }
  }
}